<template>
  <v-container
    id="dashboard-view"
    fluid
    tag="section"
    class="pa-0"
  >
    <v-card class="shiftWarp">
      <v-row class="infoWarp d-flex align-center">
        <v-col
          cols="1"
          class="lblTitle mb-6"
        >
          <h3>{{ $t('messages.EvaluationTime') }}</h3>
        </v-col>
        <v-col
          cols="2"
          class="lblTitle"
        >
          <v-select
            v-model="itemYear"
            :items="optionsYears"
            outlined
            :no-data-text="$t('table.messages.no_data')"
          />
        </v-col>
        <v-col
          cols="1"
          offset="1"
          class="lblTitle mb-6"
        >
          <h3>{{ $t('messages.quarter') }}</h3>
        </v-col>
        <v-col
          cols="2"
          class="lblTitle"
        >
          <v-select
            v-model="itemQuarter"
            :items="optionsQuarters"
            outlined
            :no-data-text="$t('table.messages.no_data')"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="2"
        >
          <v-btn
            large
            color="forth"
            class="mb-5 pt-0 pl-10 pr-10 white--text d-flex justify-star"
            @click="openAddDialog"
          >
            <span class="font-weight-bold text-h5">
              {{ $t('messages.addShift') }}</span>
          </v-btn>
        </v-col>
        <v-col>
          <v-row class="d-flex justify-end">
            <v-col cols="5">
              <div class="align-self-center">
                <v-text-field
                  id="search"
                  v-model="search"
                  name="search pa-0"
                  :placeholder="$t('placeholder.searchEvaluation')"
                  outlined
                  clearable
                  @keydown.enter="initialize"
                />
              </div>
            </v-col>
            <v-col cols="2">
              <div class="mb-9">
                <v-btn
                  large
                  color="primary"
                  @click="initialize()"
                >
                  <span class="pr-5 pl-5 font-weight-bold text-h5">
                    {{ $t('table.messages.search') }}</span>
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-data-table
            :headers="headers"
            :options.sync="options"
            :items="listEvaluateParttime"
            class="elevation-1 "
            stle
            :loading-text="$t('table.messages.loading')"
            :loading="loading"
            :items-per-page="10"
            :server-items-length="count"
            :footer-props="{
              itemsPerPageOptions: [5, 10, 15, 100],
              showFirstLastPage: false,
              'items-per-page-text': $t('table.messages.items_per_page'),
            }"
            :no-data-text="$t('table.messages.no_data')"
          >
            <template
              v-slot:footer.page-text
              class="mr-0"
            >
              {{ $t('table.messages.page') }}
              {{ options.page }}
            </template>

            <template v-slot:item.index="props">
              {{ getIndex(props.index) }}
            </template>

            <template v-slot:item.employeeId="props">
              <span v-if="isFullTime">
                {{ props.item.employeeId }}
              </span>
              <span v-else>
                {{ props.item.parttimeEmployee && props.item.parttimeEmployee.manageId }}
              </span>
            </template>

            <template v-slot:item.reviewer="props">
              <span
                v-if="isFullTime"
                class="font-weight-bold textName"
              >
                {{ props.item.admin ? props.item.admin.name : '' }}
              </span>
              <span
                v-else
                class="font-weight-bold textName"
              >
                {{ props.item.employee ? props.item.employee.user.name : '' }}
              </span>
            </template>

            <template v-slot:item.name="props">
              <span
                v-if="isFullTime"
                class="font-weight-bold textName"
              >
                {{ props.item.employee ? props.item.employee.user.name : '' }}
              </span>
              <span
                v-else
                class="font-weight-bold textName"
              >
                {{ props.item.parttimeEmployee ? props.item.parttimeEmployee.user.name : '' }}
              </span>
            </template>

            <template v-slot:item.updatedAt="props">
              {{ props.item.updatedAt | formatDate }}
            </template>

            <template v-slot:item.action="{ item }">
              <v-icon
                color="#5CA6D2"
                class="mr-2"
                @click="editItem(item)"
              >
                mdi-pencil-circle
              </v-icon>
              <v-icon
                dark
                color="#C5DCFA"
                @click="deleteItem(item)"
              >
                mdi-delete-circle
              </v-icon>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card>
    <v-snackbar v-model="snackbar">
      {{ snackbarMessage }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          {{ $t('messages.close') }}
        </v-btn>
      </template>
    </v-snackbar>
    <v-dialog
      v-model="dialog"
      max-width="800px"
      @close="close"
      @click:outside="close"
    >
      <v-card>
        <validation-observer
          v-slot="{ handleSubmit }"
          ref="observer"
        >
          <v-form
            ref="form"
            lazy-validation
            @submit.prevent="handleSubmit(onSubmit)"
          >
            <v-card>
              <v-card-title
                class="font-weight-bold text-h4 color-current-date"
              >
                <v-row>
                  <v-col class="d-flex align-center justify-center">
                    <div>
                      <div class="text-center">
                        評価リスト 新補充
                      </div>
                      <div class="text-center">
                        {{ formatDate(editedItem.updatedAt) }}
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </v-card-title>
              <v-card-text>
                <div class="borderDialog p-5">
                  <v-row class="d-flex align-items-center">
                    <v-col
                      cols="3"
                      offset="1"
                      class="justify-start grey-text"
                    >
                      <div class="mt-5">
                        {{ $t('table.headers.name') }}
                        <span class="red--text">*</span>
                      </div>
                    </v-col>
                    <v-col
                      class="align"
                      cols="6"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        name="inputSearch"
                        rules="required"
                      >
                        <v-autocomplete
                          v-model="nameEmployee"
                          :items="listEmployees"
                          :clearable="!editMode"
                          :loading="isLoading"
                          :search-input.sync="inputSearch"
                          :no-data-text="$t('table.messages.no_data')"
                          :item-text="getName"
                          item-value="id"
                          :error-messages="errors[0]"
                          name="inputSearch"
                          :readonly="editMode"
                          outlined
                          auto-select-first
                          return-object
                        />
                      </validation-provider>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      cols="3"
                      offset="1"
                      class="d-flex justify-start grey--text"
                    >
                      <div class="mt-5">
                        {{ $t('table.headers.attendance') }}
                        <span class="red--text">*</span>
                      </div>
                    </v-col>
                    <v-col
                      class="align"
                      cols="6"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        name="attendance"
                        rules="required"
                      >
                        <v-select
                          v-model="editedItem.attendance"
                          outlined
                          :items="optionsEvaluate"
                          :no-data-text="$t('table.messages.no_data')"
                          :error-messages="errors[0]"
                          name="attendance"
                        />
                      </validation-provider>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      cols="3"
                      offset="1"
                      class="d-flex justify-start grey--text"
                    >
                      <div class="mt-5">
                        {{ $t('table.headers.ability') }}
                        <span class="red--text">*</span>
                      </div>
                    </v-col>
                    <v-col
                      class="align"
                      cols="6"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        name="ability"
                        rules="required"
                      >
                        <v-select
                          v-model="editedItem.ability"
                          outlined
                          :items="optionsEvaluate"
                          :no-data-text="$t('table.messages.no_data')"
                          :error-messages="errors[0]"
                          name="ability"
                        />
                      </validation-provider>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      cols="3"
                      offset="1"
                      class="d-flex justify-start grey--text"
                    >
                      <div class="mt-5">
                        {{ $t('table.headers.productivity') }}
                        <span class="red--text">*</span>
                      </div>
                    </v-col>
                    <v-col
                      class="align"
                      cols="6"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        name="productivity"
                        rules="required"
                      >
                        <v-select
                          v-model="editedItem.productivity"
                          outlined
                          :items="optionsEvaluate"
                          :no-data-text="$t('table.messages.no_data')"
                          :error-messages="errors[0]"
                          name="productivity"
                        />
                      </validation-provider>
                    </v-col>
                  </v-row>
                </div>
              </v-card-text>
              <v-card-actions>
                <v-row class="d-flex justify-center mb-3">
                  <v-col
                    cols="2"
                  >
                    <v-btn
                      block
                      color="primary"
                      type="submit"
                    >
                      <span class="text-h5 font-weight-bold">{{ $t("button.save") }}</span>
                    </v-btn>
                  </v-col>
                  <v-col cols="2">
                    <v-btn
                      block
                      color="grey"
                      @click="close"
                    >
                      <span class="text-h5 font-weight-bold white--text">
                        {{
                          $t("messages.close")
                        }}
                      </span>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-actions>
            </v-card>
          </v-form>
        </validation-observer>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogDelete"
      max-width="500px"
    >
      <v-card>
        <v-card-title class="font-weight-bold text-h4">
          <v-row class="d-flex align-center justify-center">
            <v-col cols="1">
              <v-icon
                large
                color="blue darken-2"
              >
                mdi-delete-outline
              </v-icon>
            </v-col>
            <v-col cols="8">
              この操作は元に戻りません。
              本当に削除してよろしいですか？
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-actions>
          <v-spacer />

          <v-btn
            class="text-h5"
            dark
            color="#E43E08"
            @click="deleteItemConfirm"
          >
            {{ $t('messages.delete') }}
          </v-btn>
          <v-btn
            class="text-h5"
            @click="closeDelete"
          >
            {{ $t('messages.cancel') }}
          </v-btn>
          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  import { get } from 'vuex-pathify'
  import { debounce } from 'lodash'
  import moment from 'moment'
  import { ValidationObserver, ValidationProvider, extend } from 'vee-validate'
  import { required } from 'vee-validate/dist/rules'
  export default {
    name: 'CommonEvaluation',
    components: {
      ValidationObserver,
      ValidationProvider,
    },
    props: {
      isFullTime: {
        type: Boolean,
        required: true,
      },
    },
    data () {
      return {
        isLoading: false,
        inputSearch: null,
        itemSelect: {},
        items: [],
        search: '',
        headers: [
          {
            text: this.$t('table.headers.index'),
            align: 'start',
            value: 'index',
            class: 'pr-0 pl-2 font-weight-bold',
            sortable: false,
            width: '5%',
          },
          {
            text: this.isFullTime ? this.$t('table.headers.agencyIdEmployee') : this.$t('table.headers.manageId'),
            align: 'center',
            value: 'employeeId',
            class: 'pr-0 pl-2 font-weight-bold',
            sortable: false,
            width: '8%',
          },
          {
            text: this.$t('table.headers.name'),
            align: 'center',
            value: 'name',
            class: 'pr-0 pl-2 font-weight-bold',
            sortable: false,
            width: '20%',
          },
          {
            text: this.$t('table.headers.dateEdit'),
            align: 'center',
            value: 'updatedAt',
            class: 'pr-0 pl-2 font-weight-bold',
            sortable: false,
            width: '10%',
          },
          {
            text: this.$t('table.headers.total'),
            align: 'center',
            value: 'total',
            class: 'pr-0 pl-2 font-weight-bold',
            sortable: false,
            width: '10%',
          },
          {
            text: this.$t('table.headers.attendance'),
            align: 'center',
            value: 'attendance',
            class: 'pr-0 pl-2 font-weight-bold',
            sortable: false,
            width: '10%',
          },
          {
            text: this.$t('table.headers.ability'),
            align: 'center',
            value: 'ability',
            class: 'pr-0 pl-2 font-weight-bold',
            sortable: false,
            width: '10%',
          },
          {
            text: this.$t('table.headers.productivity'),
            align: 'center',
            value: 'productivity',
            class: 'pr-0 pl-2 font-weight-bold',
            sortable: false,
            width: '10%',
          },
          {
            text: this.$t('table.headers.reviewer'),
            align: 'center',
            value: 'reviewer',
            class: 'pr-0 pl-2 font-weight-bold',
            sortable: false,
            width: '10%',
          },
          {
            text: '',
            align: 'center',
            value: 'action',
            class: 'pr-0 pl-2 font-weight-bold',
            sortable: false,
          },
        ],
        options: {},
        editMode: false,
        loading: false,
        snackbarMessage: '',
        snackbar: false,
        optionsEvaluate: [],
        nowDay: moment().format('YYYY年MM月DD日'),
        dialog: false,
        dialogDelete: false,
        optionsYears: [],
        itemYear: 2021,
        optionsQuarters: [],
        itemQuarter: 4,
        editedItem: {},
        detailUser: null,
        listEmployees: [],
        nameEmployee: null,
      }
    },
    computed: {
      ...get('employee', ['listEvaluateParttime', 'message', 'status', 'error', 'count']),
      listFullTime: get('employee@list'),
      ...get('parttimer', ['list', 'message', 'status', 'error']),
    },
    watch: {
      search: debounce(function (newVal) {
        this.initialize()
      }, 500),

      inputSearch: debounce(async function (newVal) {
        if (this.isLoading) return
        if (!newVal) return
        if (newVal.includes(' - ')) return
        this.isLoading = true
        const params = {
          offset: 0,
          limit: 50,
          keyword: newVal,
        }
        if (this.isFullTime) {
          await this.$store.dispatch('employee/getEmployees', params)
        } else {
          await this.$store.dispatch('parttimer/getParttimers', params)
        }
        this.isLoading = false
      }, 500),
      list (value) {
        this.listEmployees = [...value.parttimeEmployees]
      },
      listFullTime (value) {
        this.listEmployees = [...value.data.employees]
      },
      options: {
        handler () {
          this.initialize()
        },
        deep: true,
      },
      itemYear: {
        handler () {
          this.initialize()
        },
        deep: true,
      },
      itemQuarter: {
        handler () {
          this.initialize()
        },
        deep: true,
      },
      message (value) {
        if (value === 'success') {
          this.snackbarMessage = this.$t(value)
          this.initialize()
        }
      },
      error (value) {
        if (value) {
          this.snackbarMessage = this.$t(value)
          this.snackbar = true
          if (this.snackbarCloseInerval) clearInterval(this.snackbarCloseInerval)
          this.snackbarCloseInerval = setTimeout(() => {
            this.snackbar = false
          }, 5000)
        }
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
    },
    created () {
      this.$store.dispatch('employee/checkFullTime', this.isFullTime)
    },
    mounted () {
      if (localStorage.getItem('user')) {
        this.detailUser = JSON.parse(localStorage.getItem('user')).detail
      }
      const fmt = 'Y[年度]'
      this.itemQuarter = moment().quarter()
      this.itemYear = +moment().format('YYYY')
      this.optionsEvaluate = [1, 2, 3]
      this.optionsYears = [1, 2, 3, 4, 5].map(i => {
        return {
          value: +moment().subtract(i - 1, 'y').format('YYYY'),
          text: moment().subtract(i - 1, 'y').format(fmt),
        }
      })
      this.optionsQuarters = [{
        value: 1,
        text: '第1四半期',
      }, {
        value: 2,
        text: '第2四半期',
      }, {
        value: 3,
        text: '第3四半期',
      }, {
        value: 4,
        text: '第4四半期',
      }]
      extend('required', {
        ...required,
        message: this.$t('validator.required'),
      })
    },
    methods: {
      initialize () {
        const tmpOptions = {
          page: this.options.page,
          size: this.options.itemsPerPage,
          year: this.itemYear,
          quarter: this.itemQuarter,
          search: this.search || '',
        }
        this.$store.dispatch('employee/getListEvaluateParttime', tmpOptions)
      },
      close () {
        this.$refs.observer.reset()
        this.nameEmployee = {}
        this.dialog = false
        this.dialogDelete = false
      },
      onSearch () {
        if (this.search) {
          this.options.page = 1
        }
        this.initialize()
      },
      getIndex (index) {
        return (this.options.page - 1) * this.options.itemsPerPage + 1 + index
      },
      openAddDialog () {
        this.editMode = false
        this.editedItem = {}
        this.listEmployees = []
        this.dialog = true
      },
      onSubmit: debounce(async function () {
        const data = {
          year: this.itemYear,
          quarter: this.itemQuarter,
          reviewer: !this.isFullTime ? this.detailUser.typeCorrespondId : this.detailUser.id,
          employeeId: this.nameEmployee.id,
          attendance: this.editedItem.attendance,
          ability: this.editedItem.ability,
          productivity: this.editedItem.productivity,
        }
        if (this.editMode) {
          data.id = this.editedItem.id
          await this.$store.dispatch('employee/updateEvaluateParttime', data)
          this.initialize()
        } else {
          delete data.parttimeEmployeeId
          await this.$store.dispatch('employee/createEvaluateParttime', data)
          this.initialize()
        }
        this.$refs.observer.reset()
        this.dialog = false
      }, 500),
      editItem (item) {
        this.editMode = true
        this.editedItem = { ...item }
        this.listEmployees = [{
          name: this.isFullTime ? item.employee.user.name : item.parttimeEmployee.user.name,
          id: this.isFullTime ? item.employeeId : item.parttimeEmployeeId,
          agencyId: this.isFullTime ? item.employee.agencyId : null,
          manageId: this.isFullTime ? null : item.parttimeEmployee.manageId,
        }]
        this.nameEmployee = this.listEmployees[0]
        this.dialog = true
      },
      deleteItem (item) {
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },
      async deleteItemConfirm () {
        try {
          await this.$store.dispatch('employee/deleteEvaluateParttime', this.editedItem.id)
          this.initialize()
        } catch (error) {}
        this.closeDelete()
      },
      closeDelete () {
        this.dialogDelete = false
      },
      formatDate (data) {
        return data ? moment(data).format('YYYY年度　第Q四半期') : this.itemYear + '年度　第' + this.itemQuarter + '四半期'
      },
      getName (data) {
        return this.isFullTime ? data.agencyId + ' - ' + data.name : data.manageId + ' - ' + data.name
      },
    },
  }
</script>

<style scoped lang="sass">
.border-right
  border-right: thin solid rgba(0, 0, 0, 0.12) !important

.shiftWarp
  margin-top: 15px
  padding: 0 50px

  .infoWarp
    padding-top: 20px

  .lblTitle
    color: #5CA6D2

.textName
  cursor: pointer
  color: #37CCC5

.borderDialog
  margin-top: 24px
  border: 1px solid #25ACD8
  border-radius: 5px
  padding: 30px 20px

.title-total-user , .title-group-job
  color: #B5B5C3
  size: 20px
  font-weight: 600

::v-deep .color-current-date
  background-color:#B6EDFF

</style>
